/*------------------------------------*\
    #BODY
\*------------------------------------*/

// HTML base styles --> Set the html element's height to at least 100% of the viewport.
html {
  min-height: 100vh;
  font-size: $font-size-sm;
}

/**
 * Body base styles – Set the body element's height to at least 100% of the viewport.
 *    This is used to achieve a sticky footer
 */
body {
  min-height: 100vh; /* 1 */
  font-family: $font-family-primary;
  @include typographyBody();
  background-color: $color-body-bg;
  color: $color-body-text;
}