.c-pricing-table {
  background: $color-gray-000;
  border-radius: $border-radius;
  margin: 5rem auto;
  width: 100%;
  .h2 {
    padding: 3rem 0 1rem;
  }

  &__wrapper {
    max-width: 1200px;
    overflow-x: scroll;
  }

  &__heading {
    padding: 0.1rem 1.5rem;
    text-align: right;
    color: $color-action-blue-700;
    font-weight: 400;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &--highlight {
      color: $color-white;
    }
    width: 3%;

    @media all and (min-width: 451px) and (max-width: 1510px) {
      text-align: center;
      white-space: normal;
    }

    @media all and (min-width: 410px) and (max-width: 450px) {
      text-align: left;
      padding: 0.1rem;
      font-size: small;
    }

    @media all and (max-width: 410px) {
      text-align: left;
      padding: 0.1rem;
      font-size: x-small;
    }
  }
  &__column {
    &--highlight {
      background: $color-action-blue-700;
    }
    &--1 {
      width: 25%;
    }
    &--2 {
      width: 25%;
    }
    &--3 {
      width: 25%;
    }
  }
  &__detail {
    padding: 0.1rem 0rem;
    text-align: center;
    font-weight: 700;
    vertical-align: middle;
    color: $color-action-blue-700;

    @media all and (max-width: 450px) {
      font-size: 1.75rem;
    }

    @media all and (max-width: 410px) {
      font-size: 1.25rem;
    }

    @media all and (max-width: 320px) {
      font-size: 1rem;
    }

    .fal {
      font-size: 2.5rem;
      @media all and (max-width: 450px) {
        font-size: 1.75rem;
      }
      @media all and (max-width: 410px) {
        font-size: 1.25rem;
      }
      @media all and (max-width: 320px) {
        font-size: 1rem;
      }
    }
    &--disabled {
      opacity: 0.4;
    }

    &--highlight {
      color: $color-white;
      padding: 0;
    }
    &--big {
      @media all and (min-width: 621px) and (max-width: 1510px) {
        font-size: 2.5rem;
      }

      @media all and (min-width: 511px) and (max-width: 621px) {
        font-size: 2rem;
      }

      @media all and (min-width: 450px) and (max-width: 511px) {
        font-size: 1.5rem;
      }

      // @media all and ()

      font-size: 2.5rem;

      padding-top: 2rem;
      padding-bottom: 0.75rem;
      white-space: normal;
      overflow-wrap: anywhere;
    }
    &--small {
      font-size: 1.5rem;
      padding-bottom: 3rem;
      white-space: normal;
      overflow-wrap: anywhere;
    }
  }
  &__footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $color-gray-600;
    font-weight: 400;
    font-size: 1.5rem;
    margin-bottom: 3rem;
    &--highlight {
      color: $color-action-blue-100;
      display: flex;
    }
  }
  &__btn {
    @media all and (min-width: 450px) and (max-width: 1510px) {
      width: 100%;
      margin-top: 2rem;
    }
    width: 28rem;
    padding: 1.25rem 1.25rem;
  }
  &__btn--highlight {
    @media all and (min-width: 450px) and (max-width: 1510px) {
      width: 100% !important;
      margin-top: 2rem;
    }

    @media all and (min-width: 450px) and (max-width: 620px) {
      font-size: 1.5rem;
    }
    width: 28rem;
    padding: 1.25rem 1.25rem;
    background: $color-white;

    border: none;
    &:hover,
    &:focus {
      background-color: $color-gray-100;
    }
    color: $color-action-blue-700 !important;
  }
  &__extend {
    position: relative;
    &--top::after {
      content: '';
      position: absolute;
      top: -2rem;
      width: 100%;
      height: 3rem;
      right: 0;
      left: 0;
      border-radius: 1rem;
      background: $color-action-blue-700;
    }
    &--bottom::after {
      content: '';
      position: absolute;
      bottom: -2rem;
      right: 0;
      left: 0;
      width: 100%;
      height: 3rem;
      border-radius: 1rem;
      background: $color-action-blue-700;
    }
  }
  @media all and (max-width: $bp-large) {
    .h2 {
      padding: 2rem 0 1rem;
    }
    .c-pricing-table__column {
      padding: 2rem;
    }
    .c-pricing-table__detail {
      padding: 0;
    }
  }
}

.c-pricing-table-mobile {
  @extend .c-pricing-table;

  &--small {
    @media all and (min-width: 451px) and (max-width: 1510px) {
      display: none;
    }
  }

  &--medium {
    @media all and (max-width: 450px) {
      display: none;
    }
  }
}

.c-pricing-table-medium {
  background: $color-gray-000;
  border-radius: $border-radius;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;

  .h2 {
    padding: 3rem 1.5rem 1rem;
    font-size: 1em;
  }

  tr {
    padding: 0.35em;
    font-size: 0.85em;
  }

  th,
  td {
    padding: 0.45em;
    text-align: center;
  }

  th {
    font-size: 1em;
  }
}

.c-pricing-table-card {
  width: 100%;
}
