/*------------------------------------*\
    #VISIBILITY CLASSES
\*------------------------------------*/

// Is Hidden --> Completely remove from the flow and screen readers.
.u-is-hidden {
  display: none !important;
  visibility: hidden !important;
}

// Is Visibly Hidden --> Completely remove from the flow but leave available to screen readers.
.u-is-vishidden {
  position: absolute !important;
  overflow: hidden;
  width: 1px;
  height: 1px;
  padding: 0;
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
}

.u-mobile-only {
  @media all and (min-width: 1511px) {
    display: none !important;
  }
}

.u-blur {
  filter: blur(0.5rem);
}
.u-desktop-only {
  @media all and (max-width: 1510px) {
    display: none !important;
  }
}

.u-sidebar-hidden {
  @media all and (min-width: $bp-large) {
    display: none !important;
  }
}
