.rbt {
  color: $color-btn-outline;
  z-index: inherit;
}

.rbt-input-main {
  border: 1px solid $color-btn-outline;
  &::placeholder {
    color: $color-btn-outline;
  }
}

.rbt-menu {
  list-style: none;
  border-radius: $border-radius;
  box-shadow: 0 4px 8px 0 rgba(138, 148, 153, 0.2),
    0 6px 20px 0 rgba(138, 148, 153, 0.19);
  background-color: white;
  padding: 1.5rem 0 0.5rem 0rem;
  margin: 0;
  border: 1px solid $color-gray-200;
  z-index: 1;
  display: flex !important;
  flex-direction: column;
  font-family: 'Roboto Mono', monospace;
  max-height: fit-content !important;
}

.dropdown-item {
  padding-left: 2rem;
  border-left: 3px solid transparent;
  overflow: unset !important;
  &:hover {
    border-color: $color-btn-outline-bg-hover;
  }
  width: 100%;
  > a {
    display: block;
    width: 100%;
  }
}

.rbt-menu .dropdown-header {
  cursor: default;
  display: flex;
  justify-content: space-between;
  padding-left: 2rem;
  color: $color-gray-500;
  align-items: center;
  font-family: $font-family-primary;
  font-weight: 700;
  font-size: 1.5rem;
  text-transform: uppercase;
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
  border-bottom: 1px solid $color-gray-100;
}

.rbt-aux .rbt-close {
  margin-top: 0;
  background-color: white;
  border: 0;
  font-size: 2rem;
  color: $color-btn-outline;
  z-index: inherit;
}

.rbt-input-hint {
  // top: 0.94rem !important;
  overflow: hidden;
  width: 100%;
  font-family: $font-family-mono;
}
