.c-developer-console {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background-color: transparent;
  padding: 1.5rem;
  z-index: 3;
  border-radius: 1rem;
  &--is-active {
    background-color: #42c140;
    .c-developer-console__icon {
      color: white;
    }
  }
  &__title {
    color: #56e254;
    margin: 0 0 1rem 0;
  }
  &__icon {
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
  }
  &__field {
    margin-top: 1rem;
  }
  &__label {
    color: white !important;
    display: inline-block;
    width: 75px;
  }
  &__button {
    width: 120px;
    border: 0;
    padding: 0.55rem;
  }
}
