/*------------------------------------*\
    #CURSOR
\*------------------------------------*/

.u-cursor-pointer {
  cursor: pointer;
}

.u-cursor-none {
  cursor: default !important;
}

.u-cursor-help {
  cursor: help;
}

.u-cursor-text {
  cursor: text;
}
