.c-field {
  position: relative;
  margin-bottom: 2rem;
  margin-right: 0.3rem;
  margin-left: 0.3rem;
  padding: 2px;
  &__label {
    font-size: $font-size-sm;
    text-transform: uppercase;
  }
  &__input-icon {
    position: absolute;
    padding: 11px 14px 0 0;
    right: 0;
  }
  &__note {
    color: $color-gray-600;
  }
  &__warning {
    color: $color-utility-warning;
  }
  &__error {
    color: $color-utility-error;
  }
  &__toolTip {
    color: $color-links;
  }
  &__content-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    .c-input {
      margin-right: 1rem;
    }
  }
  input:disabled {
    background: $color-gray-000;
  }
  input::placeholder {
    color: $color-gray-500;
  }
}

.c-field .c-input__error,
.c-field .c-input__error:focus {
  border-color: $color-utility-error;
  box-shadow: 0 0 0 0.15em #e6171740;
}

.c-select.banner-ERROR, .c-select.banner-ERROR select.banner-ERROR {
  background:  rgb(220,141,148);
  border-color: rgb(190,30,45);
  color: rgb(190,30,45);
}
.c-select.banner-ERROR:hover, .c-select.banner-ERROR select.banner-ERROR:hover {
  border-color: rgb(190,30,45);
  color: rgb(190,30,45, 0.7);
}
.c-select.banner-WARNING, .c-select.banner-WARNING select.banner-WARNING {
  background:  rgb(255,255,138);
  border-color: rgb(255, 103, 0);
  color: rgb(255, 103, 0);
}
.c-select.banner-WARNING:hover, .c-select.banner-WARNING select.banner-WARNING:hover {
  border-color: rgb(255, 103, 0);
  color: rgb(255, 103, 0, 0.7);
}
.c-select.banner-GOOD, .c-select.banner-GOOD select.banner-GOOD {
  background:  rgb(197,230,194);
  border-color: rgb(63, 156, 56);
  color: rgb(63, 156, 56);
}
.c-select.banner-GOOD:hover, .c-select.banner-GOOD select.banner-GOOD:hover {
  border-color: rgb(63, 156, 56);
  color: rgb(63, 156, 56, 0.7);
}

.zindex-1 {
  z-index: 1 !important;
}

.cursor-auto {
  cursor: auto !important;
}