@import '../../styles/molecules/select';

.ReactTable {
  border: 0;
  position: relative;

  @media all and (max-width: (40em - 0.0625em)) {
    display: none;
  }
}

.rt-thead {
  background: $color-gray-000;
  border: 0;
  border-radius: 10px;
  box-shadow: none !important;
  padding: $spacing-small 0px $spacing-small 0px;
}

.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc {
  box-shadow: none;
}

.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc {
  box-shadow: none;
}

.ReactTable .rt-thead .rt-tr {
  text-align: left;
  text-transform: uppercase;
  font-size: $font-size-sm;
  color: $color-gray-500;
  font-weight: 500;
}

.ReactTable .rt-tbody {
  margin-left: 5px;
}

.rt-td {
  border-right: 0 !important;
  display: flex;
  align-items: center;
  min-height: 6rem;
}

.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td {
  border-right: 0;
}

.ReactTable .-pagination {
  background: $color-gray-000;
  border: 0;
  border-radius: 10px;
  box-shadow: none;
  padding: $spacing-small;
  row-gap: 1rem;
  justify-content: space-between;
}

.ReactTable .-pagination select {
  cursor: pointer;
}

.ReactTable .-pagination input,
.ReactTable .-pagination select {
  border-radius: 3rem;
  padding: 0 2rem;
  height: 2.7rem;
  background-color: white;
  border: 0;
  color: $color-action-blue-300;
  height: 5rem;
}

.ReactTable .-pagination .-next {
  flex: 0 0;
}

.ReactTable .-pagination .-btn {
  color: $color-btn-outline;
  background-color: white;
  width: inherit;
  height: 5rem;
  padding: 0 2rem;
  border-radius: 50px;
  margin-right: 0;
  &:hover {
    background-color: $color-action-blue-300 !important;
  }
}

.ReactTable .-pagination .-center {
  justify-content: initial;
}

.ReactTable .-pagination .-btn[disabled]:hover {
  background-color: $color-gray-100 !important;
  cursor: not-allowed;
}

.ReactTable .-pagination .-pageJump input {
  border-radius: 20px;
  border: 0;
  background-color: white;
}

.ReactTable .-pagination .-totalPages {
  font-weight: bold;
}

.ReactTable .-pagination .-pageInfo,
.ReactTable .-pagination .-pageSizeOptions {
  margin: auto 10px;
}
