/*------------------------------------*\
    #TEXT
\*------------------------------------*/

// Paragraph base styles
p {
  margin-bottom: $spacing;
}

// Blockquote base styles
blockquote {
  font-style: italic;
  border-left: 1px solid $color-gray-500;
  color: $color-gray-500;
  padding-left: 1rem;
  margin-bottom: $spacing;
}

// Horizontal rule base styles
hr {
  border: 0;
  height: 4px;
  background: $color-gray-100;
  margin: 0.5rem 0 2rem;
}

// Selection styles
::-moz-selection {
  color: $color-text-highlight;
  background: $color-text-highlight-bg; /* Gecko Browsers */
}

::selection {
  color: $color-text-highlight;
  background: $color-text-highlight-bg; /* WebKit/Blink Browsers */
}

// Code base styles
code {
  display: inline-block;
  background: $color-gray-900;
  color: white;
  border: 1px solid $color-gray-200;
  padding: 0.2rem 0.5rem;
  line-height: 1.2;
  font-size: 0.85rem;
}

// Preformatted text base styles
pre {
  background: $color-gray-100;
  border: 1px solid $color-gray-200;
  font-size: $font-size-med;
  padding: 1rem;
  overflow-x: auto;

  // Remove border from code within preformatted text block
  code {
    border: 0;
    font-size: $font-size-med;
  }
}

// Code with languages associated with them –-> Override Prism sysles for code blocks with language
samp,
code[class*='language-'],
pre[class*='language-'] {
  font-family: 'Roboto Mono', monospace !important;
}

.text-smaller {
  font-size: smaller;
}

.highlighted {
  background-color: lighten(yellow, 20%);
  font-weight: bold;
  transition: all 0.4s ease;
}
