// #TEXT
$color-body-text: $color-gray-700;
$color-body-bg: $color-white;
$color-error: $color-utility-error;
$color-success: $color-utility-success;
$color-label-info-bg: $color-gray-000;

// Highlight colors
$color-text-highlight: $color-gray-800;
$color-text-highlight-bg: $color-gray-500;

// #LINKS
$color-links: $color-action-blue-500 !default;
$color-links-hover: $color-action-blue-300 !default;
$color-links-active: $color-action-blue-700 !default;
$color-links-disabled: $color-gray-600 !default;

// #BUTTONS
$color-btn-primary: $color-white !default;
$color-btn-primary-bg: $color-action-blue-500 !default;
$color-btn-primary-bg-hover: $color-action-blue-300 !default;
$color-btn-primary-border: $color-action-blue-500 !default;

$color-btn-secondary: $color-white !default;
$color-btn-secondary-bg: $color-action-blue-300 !default;
$color-btn-secondary-bg-hover: $color-action-blue-500 !default;
$color-btn-secondary-border: $color-action-blue-300 !default;

$color-btn-outline: $color-action-blue-500 !default;
$color-btn-outline-bg: transparent !default;
$color-btn-outline-bg-hover: $color-action-blue-300 !default;
$color-btn-outline-text-hover: $color-white !default;
$color-btn-outline-border: $color-action-blue-500 !default;

$color-btn-filter-border: $color-gray-200 !default;
$color-btn-filter-text: $color-gray-700 !default;

$color-btn-dark: $color-white !default;
$color-btn-dark-bg: $color-action-blue-700 !default;
$color-btn-dark-bg-hover: $color-action-blue-500 !default;

$color-btn-light: $color-action-blue-300 !default;
$color-btn-light-bg: $color-white !default;
$color-btn-light-text-hover: $color-white !default;
$color-btn-light-bg-hover: $color-action-blue-300 !default;

$color-btn-icon-delete: $color-utility-error !default;

$color-tabset-text: $color-white !default;
$color-tabset-bg: $color-action-blue-500 !default;
$color-tabset-bg-active: $color-white !default;

// FORMS
$color-form: $color-gray-900 !default;
$color-form-bg: $color-white !default;
$color-form-border: $color-action-blue-500 !default;
$color-form-border-focus: $color-action-blue-500 !default;
$color-form-border-error: $color-utility-error !default;
$color-form-label: $color-gray-900 !default;
$color-form-info: $color-gray-700;
$color-form-placeholder: $color-gray-500;

$color-form-disabled: $color-gray-500 !default;
$color-form-bg-disabled: $color-gray-100 !default;
$color-form-border-disabled: $color-gray-100 !default;

// BOX
$color-box-bg: $color-gray-000 !default;
$color-box-bg-dark: $color-action-blue-700 !default;
$color-box-title: $color-action-blue-700 !default;

// REPORTS
$color-report-border: $color-gray-100 !default;
$color-report-stat-text: $color-action-blue-700 !default;
$color-report-label: $color-gray-500 !default;
$color-report-content: $color-gray-700 !default;
$color-report-bar-bg: $color-gray-000 !default;
$color-report-bar-label: $color-gray-700 !default;
$color-report-bar-fill: $color-action-blue-200 !default;
