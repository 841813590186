a.c-btn {
  display: flex;
  width: fit-content;
}
a.c-btn-full {
  display: flex;
  width: 100%;
}
.c-btn {
  display: inline-block;
  border: $border-thickness solid $color-btn-primary-border;
  background: $color-btn-primary-bg;
  transition: all 0.4s ease;
  color: $color-btn-primary;
  padding: 0 2rem;
  min-height: 5rem;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  font-weight: bold;
  text-transform: capitalize;
  text-align: center;
  transition: all $anim-fade-quick $anim-ease;
  border-radius: 26px;
  white-space: normal;

  &__inner {
    display: flex;
    align-items: center;
  }

  &__icon {
    height: 1.75rem;
    fill: $color-btn-primary;
    transition: fill $anim-fade-quick $anim-ease;
    padding-right: 1rem;
  }

  &:hover,
  &:focus {
    transition: all 0.4s ease;
    color: $color-btn-primary;
    background-color: $color-action-blue-300;
    border-color: $color-action-blue-300;
  }

  &:disabled,
  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.c-btn-success {
  background-color: $color-utility-success;
  border: 1px solid $color-utility-success;
  &:hover,
  &:focus {
    transition: all 0.4s ease;
    color: $color-btn-primary;
    background-color: $color-utility-success-light;
    border-color: $color-utility-success-light;
  }
}

.c-btn-error {
  background-color: $color-utility-error;
  border: 1px solid $color-utility-error;
  &:hover,
  &:focus {
    transition: all 0.4s ease;
    color: $color-btn-primary;
    background-color: $color-utility-error-light;
    border-color: $color-utility-error-light;
  }
}

.c-btn-error-brand {
  @extend .c-btn-error;
  color: #c2000c;
  border-color: #c2000c;
  background-color: #fff;
}

.c-btn-warning {
  background-color: $color-utility-warning;
  border: 1px solid $color-utility-warning;
  &:hover,
  &:focus {
    transition: all 0.4s ease;
    color: $color-btn-primary;
    background-color: $color-utility-warning-light;
    border-color: $color-utility-warning-light;
  }
}

.c-btn-small {
  @extend .c-btn;
  transform: scale(0.65, 0.65);
}

.c-btn-medium {
  @extend .c-btn;
  transform: scale(0.8, 0.8);
}

.c-btn-alt {
  @extend .c-btn;
  border-color: $color-btn-secondary-border;
  background: $color-btn-secondary-bg;

  &:hover,
  &:focus {
    background-color: $color-btn-secondary-bg-hover;
  }
}

.c-btn-outline {
  @extend .c-btn;
  color: $color-btn-outline;
  border-color: $color-btn-outline-border;
  background: $color-btn-outline-bg;

  &:hover,
  &:focus {
    background-color: $color-btn-outline-bg-hover;
  }
}

.c-btn-dark {
  @extend .c-btn;
  color: $color-btn-dark;
  background: $color-btn-dark-bg;
  border: 0;

  &:hover,
  &:focus {
    background-color: $color-btn-dark-bg-hover;
  }
}

.c-btn-light {
  @extend .c-btn;
  color: $color-btn-light;
  background: $color-btn-light-bg;
  border: 0;

  &:hover,
  &:focus {
    color: $color-btn-light-text-hover;
    background-color: $color-btn-light-bg-hover;
  }
}

.width-100 {
  width: 100% !important;
}

.display-flex {
  display: flex !important;
}

.shopid-style {
  display: block;
  text-wrap: wrap;
}

.usercol-clickable {
  font-size:  inherit !important;
  color: #17a1e6 !important;
  cursor: pointer !important;
}

.c-btn-icon {
  @extend .c-btn;
  background-color: transparent;
  border-color: transparent;
  padding: 1.4rem 2rem;
  width: 5rem;
  height: 5rem;
  color: $color-action-blue-500;
  display: flex;
  justify-content: center;

  .c-btn__icon {
    padding: 0;
  }

  &:focus {
    background-color: transparent;
    border-color: transparent;
    padding: 1.4rem 2rem;
    color: $color-action-blue-500;
  }
  &:hover {
    color: $color-action-blue-300;
    background-color: $color-gray-100;
    border-color: $color-gray-100;
    border-radius: 90%;
  }

  &--delete,
  &--delete:focus,
  &--delete:hover {
    color: $color-btn-icon-delete;
  }
}

.c-btn-icon--green {
  @extend .c-btn-icon;
  color: $color-utility-success;
  &:hover {
    color: $color-utility-success;
    background-color: $color-gray-100;
    border-color: $color-gray-100;
    border-radius: 90%;
  }
}

.c-btn-icon--red {
  @extend .c-btn-icon;
  color: $color-utility-error;

  &:hover {
    color: $color-utility-error;
    background-color: $color-gray-100;
    border-color: $color-gray-100;
    border-radius: 90%;
  }
}

.c-btn-icon--highlight {
  @extend .c-btn-icon;
  color: #fff;
}

.c-btn-icon-alt {
  @extend .c-btn-icon;
  background-color: $color-btn-primary-bg;
  border-color: $color-btn-primary-bg;
  color: $color-btn-primary;
}

.c-btn-icon-outline {
  @extend .c-btn-outline;
  padding: 1.4rem 2rem;
  padding-right: 1rem !important;
  width: 5rem;
  height: 5rem;
  display: flex;
  justify-content: center;
}

.c-btn-filter {
  @extend .c-btn;
  padding: 0.8rem 0.8rem 0.8rem 1rem;
  border-color: $color-btn-filter-border;
  color: $color-btn-filter-text;
  background: $color-btn-outline-bg;

  .c-btn__inner {
    padding-right: 3rem;
    position: relative;
  }

  .c-btn__icon {
    position: absolute;
    top: 0rem;
    right: 0rem;
    height: 1.75rem;
    padding: 0 0 0 1rem;
    font-size: 2.5rem;
  }

  &:hover,
  &:focus {
    background-color: $color-btn-outline-bg-hover;
  }
}

.c-btn-link {
  @extend .c-btn;
  border-color: transparent;
  // height: fit-content;
  color: $color-action-blue-500;
  background: transparent;
  &:hover,
  &:focus,
  &__active {
    color: $color-action-blue-300;
    background-color: $color-gray-000;
    border-color: transparent;
  }
}

.c-btn-link-thin {
  @extend .c-btn-link;
  color: $color-action-blue-300;
  text-transform: none;
  padding-left: 0;
  font-weight: normal;
}


.c-btn-link-thin-chunk {
  @extend .c-btn-link-thin;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
  margin: auto 0;
}

.c-btn-full {
  @extend .c-btn;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  &:hover,
  &:focus {
    background-color: $color-btn-primary-bg-hover;
  }
}

.c-btn-wrap {
  @extend .c-btn;
  white-space: normal;
}

.c-btn-mobile-close {
  @extend .c-btn;
  margin-left: 90%;
  color: $color-action-blue-500;
  height: 20px !important;
  min-height: 20px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  &:focus {
    height: 20px !important;
    min-height: 20px !important;
  }

  @media all and (min-width: $bp-large) {
    display: none;
  }
}

.c-btn-link-mobile-nav {
  padding: 0px;
}
