.responsiveTable {
  @media all and (min-width: 40em) {
    display: none;
  }

  tr {
    color: $color-gray-700;
    border: 1px solid $color-gray-300 !important;
    border-radius: 10px;
    margin-bottom: 10px;
  }

  td {
    overflow-wrap: anywhere !important;
  }

  thead {
    display: none !important;
  }

  tbody {
    border-radius: 10px;
  }
}

.pagination-input {
  width: 50%;
  margin: 0 auto;
  display: flex;
}
