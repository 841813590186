@import '../molecules/button';

.Bootstrap__dateRangePicker {
  border: $border-thickness solid $color-btn-outline-border;
  background: $color-btn-outline-bg;
  color: $color-btn-outline;
  padding: 1.2rem 2rem;
  border-radius: 26px;
  @media all and (max-width: $bp-large) {
    height: fit-content;
  }
  font-size: 16px;
  line-height: normal;
  height: 5rem;
  margin-bottom: 2rem;
  cursor: pointer;
  transition: all 0.4s ease;
  transition: all $anim-fade-quick $anim-ease;

  &:hover {
    border-color: white;
    color: white;
    background: $color-action-blue-300;
  }
}

.daterangepicker {
  z-index: 9999 !important;
  width: auto;
}

.daterangepicker .drp-calendar {
  max-width: 300px;
}

.daterangepicker .ranges ul {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.daterangepicker .ranges li {
  @extend .c-btn-outline;
  margin-bottom: 3px;
  padding-top: 1.3rem !important;
  margin: 0.3rem 1.3rem;
}

.daterangepicker .calendar-table th {
  line-height: 13px;
}

.daterangepicker .calendar-table thead {
  background-color: white;
}

.daterangepicker .calendar-table thead .monthselect {
  font-weight: bold;
}

.daterangepicker .calendar-table thead .yearselect {
  font-weight: bold;
}

.daterangepicker .calendar-table td {
  line-height: 0;
}

.daterangepicker .drp-buttons .btn {
  @extend .c-btn;
}

.daterangepicker select.hourselect,
.daterangepicker select.minuteselect,
.daterangepicker select.secondselect,
.daterangepicker select.ampmselect {
  border-radius: 26px;
  padding-left: 1rem;
  border: $border-thickness solid $color-btn-primary-border;
  background-color: #fff;
}

.daterangepicker .drp-selected {
  font-size: 16px;
  font-weight: bold;
}

.daterangepicker .calendar-table thead .monthselect {
  font-size: 16px;
}

.daterangepicker .calendar-table thead .yearselect {
  font-size: 16px;
}
