h1,
.h1 {
  font-family: $font-family-primary;
  font-weight: 800;
  color: $brand-red;
  font-size: 3rem;
}

h2,
.h2 {
  font-family: $font-family-primary;
  font-weight: 800;
  color: $color-action-blue-700;
  font-size: 2.25rem;
}

h3,
.h3 {
  font-family: $font-family-primary;
  font-weight: 700;
  color: $brand-red;
  font-size: 1.5rem;
  text-transform: uppercase;
}

.emphasis {
  font-weight: 700;
  color: $color-action-blue-500;
}

a,
.link {
  color: $color-action-blue-300;
}

p {
  font-family: $font-family-primary;
  font-weight: 400;
  font-size: 2rem;
  overflow-wrap: break-word;

  @media all and (max-width: $bp-large) {
    text-align: center;
  }
}

code,
samp,
pre {
  font-family: $font-family-mono;
}

.mobile-display-label {
  padding: 5px 10px;
  border-radius: 10px 10px 0px 0px;
  background: $color-gray-200;
  font-size: small;
  font-weight: bold;
  width: fit-content;
}

.mobile-display-value {
  border: 2px solid $color-gray-200;
  border-radius: 0px 10px 10px 10px;
  padding: 0.5rem 1rem;
  overflow-wrap: anywhere;
  width: 100%;
}

.mobile-display-value-with-button {
  border: 2px solid $color-gray-200;
  border-right: 0px;
  border-radius: 0px 0px 0px 10px;
  padding: 0.5rem 1rem;
  overflow-wrap: anywhere;
  width: 100%;
}

.mobile-display-value-button {
  background-color: #0088cc;
  color: #fff;
  border-radius: 0 10px 10px 0;
  // height: 4rem;
  display: flex;
  align-items: center;
}
