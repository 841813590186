@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600&display=swap');

/* Style the accordion section */
.accordion__section {
  display: flex;
  flex-direction: column;
}

/* Style the buttons that are used to open and close the accordion panel */
.accordion {
  background-color: $color-gray-000;
  color: #0a4766;
  cursor: pointer;
  padding: 18px;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  transition: background-color 0.3s ease;
  box-shadow: 0 4px 8px 0 rgba(138, 148, 153, 0.2),
    0 6px 20px 0 rgba(138, 148, 153, 0.19);
  border-radius: 10px 10px 10px 10px;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.accordion:hover {
  background-color: $color-gray-200;
}

.accordion-active {
  background-color: $color-gray-300;
  border-radius: 10px 10px 0 0 !important;
}

/* Style the accordion content title */
.accordion__title {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 600;
  font-size: 2.25rem;
  text-align: left;
}

/* Style the accordion chevron icon */
.accordion__icon {
  margin-left: auto;
  transition: transform 0.6s ease;
}

/* Style to rotate icon when state is active */
.rotate {
  transform: rotate(90deg);
}

/* Style the accordion content panel. Note: hidden by default */
.accordion__content {
  background-color: white;

  overflow: auto;
  transition: max-height 0.6s ease;
  max-height: 0;
  box-shadow: 0 4px 8px 0 rgba(138, 148, 153, 0.2),
    0 6px 20px 0 rgba(138, 148, 153, 0.19);
  border-radius: 0 0 10px 10px;
}

/* Style the accordion content text */
.accordion__text {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 1em;
  padding: 18px;
}
