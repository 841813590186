.ReactTable .rt-table .rt-th,
.ReactTable .rt-table .rt-td {
  white-space: normal;
  word-break: break-word;
}

.hide-expander > .rt-expandable {
  visibility: hidden;
}

@keyframes slideDown {
  0% {
    max-height: 0px;
    opacity: 0;
  }
  100% {
    max-height: 500px;
    opacity: 1;
  }
}

.expander {
  overflow: hidden;
  animation: 1s ease-in-out 0s 1 slideDown;
}

.tofw-ell {
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
  margin: auto 0;
}